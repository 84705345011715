import * as amplitude from "@amplitude/analytics-browser";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import * as Sentry from "@sentry/remix";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  // This is your Sentry DSN
  // It's technically public, so we hardcode it here
  dsn: "https://e106115c4caca3c8f22f173019798bc1@o1184370.ingest.sentry.io/4506185528836096",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),

    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

const userProperties = {
  email: window.ENV.USER.email,
  firstName: window.ENV.USER.firstName,
  lastName: window.ENV.USER.lastName,
  churchSlug: window.ENV.USER.church_slug,
};

Sentry.setContext("church", {
  slug: window.ENV.USER.church_slug,
});

Sentry.setUser(userProperties);

if (window.ENV.ENVIRONMENT === "production") {
  amplitude.init(window.ENV.AMPLITUDE_API_KEY, window.ENV.USER.id);
  const analytics = new AnalyticsBrowser.load({
    writeKey: window.ENV.SEGMENT_API_KEY,
  });

  analytics.identify({
    userId: window.ENV.USER.id,
    traits: userProperties,
  });

  const identifyObj = new amplitude.Identify();
  identifyObj.set("email", userProperties.email);
  identifyObj.set("firstName", userProperties.firstName);
  identifyObj.set("lastName", userProperties.lastName);
  identifyObj.set("churchSlug", userProperties.churchSlug);

  amplitude.identify(identifyObj, userProperties);
}

// Use StrictMode to run React components in strict mode
// https://reactjs.org/docs/strict-mode.html
const App = () => (
  <StrictMode>
    <RemixBrowser />
  </StrictMode>
);

// Hydrate the React components when the browser is idle
const hydrate = () => {
  startTransition(() => {
    hydrateRoot(document, <App />);
  });
};

// Use requestIdleCallback if available for better performance
// https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Use requestAnimationFrame as a fallback for Safari
  // https://caniuse.com/requestidlecallback
  window.requestAnimationFrame(hydrate);
}
